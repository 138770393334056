import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Website Pre Release Check List" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE PRE RELEASE <br/>
<span> CHECK LIST</span></h2>
                                <p></p>
                            
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>CHECK LIST</div>
                        <div className="container main-about">
                          


                            
                        <p style={{fontSize: '13px'}}>The following checklist is to be executed for all websites by PragICTS before release and signed off
                          </p>
                            
                           <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR001&nbsp;&nbsp;</span>Structure, design, and content in line with the authorized scope and content documentation</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR002&nbsp;&nbsp;</span>On-Page SEO Tags in place<br/>- Title<br/>- Description<br/>- Keywords</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR003&nbsp;&nbsp;</span>Ensure that any given keyword is found in the content</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR004&nbsp;&nbsp;</span>All images are WEBP compliant and are optimized for size</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR005&nbsp;&nbsp;</span>All images are named as per the following guideline<br/>- Use descriptive keywords.<br/>- Use hyphens to separate words.<br/>- Use lowercase letters<br/>- Use a logical structure<br/>-A logical structure for naming digital image files is one that follows a clear and consistent pattern that reflects the hierarchy, category, or function of the image. A logical structure can help you organize your image files in a meaningful way, making them easier to find, sort, and manage. A logical structure can also help you avoid duplication or overwrite of image files that may have similar names or content. A common way to create a logical structure is to use prefixes or suffixes that indicate the source, type, size, or version of the image.<br/>- Use a unique identifier<br/>- A unique identifier for naming digital image files is one that distinguishes your image file from other image files that may have similar names or content. A unique identifier can help you avoid confusion, duplication, or overwrite of image files that may occur due to human error, system error, or malicious intent. A unique identifier can also help you track and measure the performance, usage, or impact of your image files, such as the number of views, clicks, or conversions. A unique identifier can be a number, a letter, a symbol, or a combination of these elements, that is added to the end or the beginning of the file name.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR006&nbsp;&nbsp;</span>All images have the ALT tag.</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR007&nbsp;&nbsp;</span> Design, positioning, and size proportions are consistent across the website</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR008&nbsp;&nbsp;</span>Google Analytics Integration is in place</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR009&nbsp;&nbsp;</span>Google Business Registration is in place</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR010&nbsp;&nbsp;</span>All functionality and links are clicked for verification and validity (broken link verification)</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR011&nbsp;&nbsp;</span>SitemapXML is correct and uploaded</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR012&nbsp;&nbsp;</span>Open Graph is implemented across all the pages of the website and the content company functionality is checked across<br/>- WhatsApp<br/>- Text Message<br/>- Twitter<br/>- Instagram<br/>- FB<br/>- Pinterest</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR013&nbsp;&nbsp;</span>Check language for spelling and grammar</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR014&nbsp;&nbsp;</span>Check all URLs, phone numbers, and emails for correctness and validity</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR015&nbsp;&nbsp;</span>Check for content duplication across the pages and website</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR016&nbsp;&nbsp;</span>Check all images for copyright compliance</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR017&nbsp;&nbsp;</span> If Google Map embeddings are found, check for validity</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR018&nbsp;&nbsp;</span>Check all social link URLs for validity</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR019&nbsp;&nbsp;</span>Check for SSL implementation</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>CLWSPR020&nbsp;&nbsp;</span>If the website is targetted for a global or regional audience, Check for CDN deployment</h6>
                          </p>
                         
                          



<div>










</div> 





















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
